<template>
    <div class="fullPageForm" v-if="!invitation.error">

        <div class="loader" v-if="isLoading(['getInvitation', 'joinNow'])"><Loader/></div>

        <div class="invitationHolder" v-if="!join">
            <div class="logoHolder"><div class="imageHolder"><img src="/images/logo-dark.png"></div></div>

            <div class="invitationText">
                <div class="header">Fast Loading Dispatch Inc</div>
                <div class="small">invited you to join dispatch dashboard.</div>
            </div>


            <div class="action">
                <router-link to="" class="default medium" @click="join = true">Join now</router-link>
            </div>

        </div>

        <div class="invitationHolder" v-else>
            <div class="logoHolder"><div class="imageHolder"><img src="/images/logo-dark.png"></div></div>

            <div class="invitationText">
                <div class="header">Fast Loading Dispatch Inc</div>
                <div class="small">invited you to join dispatch dashboard.</div>
            </div>

            <form action="" @submit.prevent="handleJoin" class="form">

                <div class="inputWithText">
                    <Input name="Dispatch name" v-model="form.name" :error="errors.name" placeholder="Dispatch name" />
                    <div class="small">You will use this name to introduce yourself to brokers & carriers.</div>
                </div>

                <Input name="Full name" v-model="form.fullName" :error="errors.fullName" placeholder="Full name" />

                <Input name="Email address" v-model="form.email" :error="errors.email" placeholder="Email address" />

                <div class="inputWithText">
                    <Input name="Username" v-model="form.username" :error="errors.username" placeholder="Username" />
                    <div class="small">You will use this username to log in.</div>
                </div>

                <Input name="Password" v-model="form.password" :error="errors.password" type="password" placeholder="Password" />
                <Input name="Repeat Password" v-model="form.repeatPassword" :error="errors.repeatPassword" type="password" placeholder="Repeat password" />
                <Input name="PIN" v-model="form.pin" :error="errors.pin" placeholder="PIN (6 digits)" />


                <button type="submit" hidden></button>
            </form>


            <div class="action">
                <router-link to="" class="default medium" @click="handleJoin">Confirm & Join</router-link>
            </div>

        </div>
        
    </div>
    <div class="fullPageForm" v-else>
        <div class="invitationHolder">
            <div class="logoHolder"><div class="imageHolder"><img src="/images/logo-dark.png"></div></div>
            <div class="invitationText">
                <div class="header">Sorry</div>
                <div class="small">{{ invitation.message }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '../../components/PageLoader.vue'

    export default {
        components: {
            Loader
        },
        data() {
            return {
                join: false,
                form: {},
                errors: {},
                invitation: {}
            }
        },
        computed: {
            invitationID() {
                return this.$route.params.id;
            }
        },
        mounted() {
            this.getInvitation();
        },
        methods: {
            getInvitation() {
                this.ajax('getInvitation', {
                    method: 'GET',
                    url: `/users/invitation/${this.invitationID}`,
                }, (err, body) => {
                    if(err) {
                        this.invitation.error = true;
                        this.invitation.message = "Your invitation has expired.";
                        return;
                    }
                    this.invitation = body;
                    this.form.email = this.invitation.email;
                });
            },
            handleJoin() {
                this.errors = {}

                this.ajax('joinNow', {
                    method: 'POST',
                    url: `/users/invitation/${this.invitation._id}/register`,
                    data: this.form
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }

                    this.$notify({
                        type: 'info',
                        text: `You have successfully registered. You can now log in.`
                    });

                    this.$router.push('/');

                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.fullPageForm {
    min-height: 100vh;
    padding: 100px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.3);
}

.invitationHolder {
    width: 100%;
    max-width: 692px;
    min-height: 100px;
    background: $bgSecondary;
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
    display: grid;
    justify-items: center;
    padding: 30px;
    row-gap: 30px;
}

.logoHolder {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    background: $bg;
    .imageHolder {
        padding: 20px;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.invitationText {
    display: grid;
    row-gap: 10px;
    text-align: center;
    justify-items: center;
    .header {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
    }
    
}

.small {
    opacity: 0.8;
    font-size: 13px;
}

.inputWithText {
    display: grid;
    row-gap: 5px;
    .small {
        text-align: center;
    }
}

.form {
    width: 100%;
    max-width: 250px;
    display: grid;
    row-gap: 20px;
}
</style>